<template>
  <TopNav :config="config" :contact="defaults.contact" />
  <main>
    <!-- Screens here -->
    <router-view
      :config="config"
      :transports="transports"
      :defaults="defaults"
      :frontImages="frontImages"
      :rooms="rooms"
      :benefits="benefits"
      :atractions="atractions"
      :contacts="defaults.contact"
      :photos="photos"
      :reviews="reviews"
      :galeryImages="photos"
    />
    <!-- No More Screens -->
  </main>
  <FooterBar :rooms="rooms" :defaults="defaults" :config="config" />
</template>

<script>
  import TopNav from "./components/TopNav.vue";
  import FooterBar from "./components/FooterBar.vue";
  // import data from '@/data/data.json';

  export default {
    name: "app",
    components: {
      FooterBar,
      TopNav,
    },
    data() {
      return {
        defaults: {
          location: {
            address:
              "Sykowno Chata,\n Myśliwska 121,\n 43-370 Szczyrk,\n Województwo Śląskie\nPolska",
            addressMaps:
              "https://www.google.com/maps/place/Sykowno+Chata/@49.7043264,19.0038893,17z/data=!3m1!4b1!4m8!3m7!1s0x4714219693d92387:0x47de40f1f597bbd9!5m2!4m1!1i2!8m2!3d49.704323!4d19.006078",
          },
          contact: {
            phone: [
              {
                name: "Main Phone",
                prefix: "(+48)",
                number: "666 737 577",
                link: "tel:0048666737577",
                svg: "../assets/SVG/whatsapp.svg",
              },
            ],
            email: [
              {
                name: "Main Email",
                address: "szczyrk@sykownochata.pl",
                link: "mailto:szczyrk@sykownochata.pl",
                svg: "../assets/SVG/email.svg",
              },
            ],
            socialNetworks: [
              {
                name: "Facebook",
                link: "https://www.facebook.com/sykowno.chata121",
                svg: "./assets/SVG/facebook.svg",
              },
              {
                name: "Instagram",
                link: "https://www.instagram.com/sykowno",
                svg: "./assets/SVG/instagram.svg",
              },
              {
                name: "Booking.com",
                link: "https://www.booking.com/hotel/pl/sykowno-chata.en-gb.html?aid=304142&label=gen173nr-1DCAEoggI46AdIM1gEaGmIAQGYAQm4ARfIAQzYAQPoAQGIAgGoAgO4AorI5pUGwAIB0gIkZjAzNDYzNjQtNzdmNC00OWM5LTk5ZWEtYTY4NTlkMjNjMmEy2AIE4AIB&sid=f94668a02a4a18b004805a719ee2b3ba&dest_id=2861716;dest_type=hotel;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1656333358;srpvid=8c205896533d0200;type=total;ucfs=1&#hotelTmpl",
                svg: "./assets/SVG/booking.svg",
              },
            ],
          },
          mainMessage: [
            {
              lang: "Polski",
              message: "Apartmenty",
            },
            {
              lang: "English",
              message: "Apartments",
            },
          ],
        },
        config: {
          routes: {
            index: {
              name: "Strona Główna",
              route: "Home",
              active: true,
              nav: true,
              sections: {
                welcome: {
                  active: true,
                },
                rooms: {
                  active: true,
                },
                photos: {
                  active: true,
                },
                benefits: {
                  active: true,
                },
                contact: {
                  active: true,
                },
                review: {
                  active: true,
                },
              },
            },
            galery: {
              name: "Galeria",
              route: "Galery",
              active: true,
              nav: true,
              sections: {},
            },
            apartments: {
              name: "Apartamenty",
              route: "Apartaments",
              active: true,
              nav: true,
              sections: {},
            },
            atractions: {
              name: "Atrakcje",
              route: "Atractions",
              active: true,
              nav: true,
              sections: {},
            },
            help: {
              name: "Pomoc",
              route: "Help",
              active: true,
              nav: false,
              sections: {},
            },
          },
          features: {
            help: {
              active: false,
            },
            reserve: {
              active: false,
            },
            darkMode: {
              active: false,
            },
            subscribe: {
              active: false,
            },
            languageSupport: {
              active: false,
              languages: [
                { name: "Polski", flag: "" },
                { name: "English", flag: "" },
              ],
            },
          },
        },
        frontImages: [
          {
            name: "Back Garden",
            src: "../assets/Photos/main3.webp",
            mini: "",
            loc: "Down there",
            insta: "www.google.com",
            views: "",
            likes: 0,
          },
          {
            name: "Back Garden",
            src: "../assets/Photos/main2.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
          {
            name: "Back Garden",
            src: "../assets/Photos/IMG20220621174804.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
        ],
        rooms: [
          {
            name: "Apartament z Balkonem",
            enabled: true,
            capacity: "1-4",
            route: [
              {
                name: "Apartamenty",
                route: "Apartaments",
                active: true,
              },
            ],
            beds: [
              {
                name: "Łóżko",
                img: "king_bed",
                capacity: "1-2",
              },
              {
                name: "Sofa",
                img: "chair",
                capacity: "1-2",
              },
            ],
            premium: false,
            info: "W skład apartamentu wchodzą:sypialnia z łóżkiem małżeńskim, pokój dzienny z narożną rozkładaną sofą (klimatyzacja) ,tv 50 cal i w pełni wyposażonym aneksem kuchennym (indukcja, lodówka, mikrofala, czajnik, ekspres do kawy na kapsułki oraz naczynia, garnki i sztućce), oraz łazienka z prysznicem (suszarka do włosów). Cały apartament wykonany jest z drewna łącznie z meblami, oprócz łazienki. Na piętrze znajdują się 2 takie apartamenty. Na ogrodzie znajduje się miejsce na grilla, oraz plac zabaw dla dzieci",
            images: [
              {
                name: "Back Garden",
                src: "../assets/Photos/pokGorny1.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokGorny2.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokGorny3.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokGorny4.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokGorny5.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
            ],
            size: 34,
            benefits: [
              {
                name: "TV",
                link: "smart_display",
              },
            ],
          },
          {
            name: "Apartament Delux",
            enabled: true,
            capacity: "1-6",
            route: [
              {
                name: "Apartamenty",
                route: "Apartaments",
                active: true,
              },
            ],
            beds: [
              {
                name: "Łóżko",
                img: "bed",
                capacity: "1-2",
              },
              {
                name: "Łóżko",
                img: "king_bed",
                capacity: "1",
              },
              {
                name: "Łóżko",
                img: "bed",
                capacity: "1",
              },
              {
                name: "Sofa",
                img: "chair",
                capacity: "1-2",
              },
            ],
            premium: true,
            info: "W skład którego wchodzą: 2 sypialnie na piętrze, jedna z łóżkiem małżeńskim, druga z dwoma pojedynczymi łóżkami oraz łazienka z wanną.Na parterze znajduje się druga łazienka z prysznicem, pokój dzienny z narożną rozkładaną sofą, TV 50 cal i kominkiem na drewno opałowe , jadalnia oraz w pełni wyposażony aneks kuchenny łącznie ze zmywarką do naczyń i ekspresem żarnowym do kawy. Apartament posiada swój własny niezależny taras do grillowania obok którego płynie górski potok. Na ogrodzie jest plac zabaw dla dzieci.",
            images: [
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDelux1.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDelux2.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDelux3.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDelux4.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDelux5.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDelux6.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDelux7.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
            ],
            size: 56,
            benefits: [
              {
                name: "Kominek",
                link: "fireplace",
              },
              {
                name: "TV",
                link: "smart_display",
              },
              {
                name: "Łazienka",
                link: "bathtub",
              },
            ],
          },
          {
            name: "Apartament z Tarasem",
            enabled: true,
            capacity: "1-4",
            route: [
              { name: "Apartamenty", route: "Apartaments", active: true },
            ],
            beds: [
              {
                name: "Łóżko",
                img: "king_bed",
                capacity: "1-2",
              },
              {
                name: "Sofa",
                img: "chair",
                capacity: "1-2",
              },
            ],
            premium: false,
            info: "W skład którego wchodzą: sypialnia z łóżkiem małżeńskim, pokój dzienny z narożną rozkładaną sofą ,tv 50 cal i w pełni wyposażonym aneksem kuchennym (indukcja, lodówka, mikrofala, czajnik oraz naczynia, garnki i sztućce), oraz łazienka z prysznicem (suszarka do włosów). Na parterze znajdują się dwa takie niezależne apartamenty, tak samo wyposażone i o takiej samej powierzchni z własnymi niezależnymi tarasami do grillowania, znajdującymi się przy górskim potoku. Na wspólnym korytarzu znajduje się ekspres żarnowy zawsze gotowy aby zrobić kawę oraz odkurzacz dla wielbicieli zwierząt. Dom zbudowany jest całkowicie z bali drewnianych przekładanych mszeniem z wiórów drewnianych, podzielony na 4 apartamenty 2 na parterze i 2 na piętrze.",
            images: [
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDolny1.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDolny2.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDolny3.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDolny4.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
              {
                name: "Back Garden",
                src: "../assets/Photos/pokDolny5.webp",
                mini: "",
                loc: "Outside",
                insta: "",
                views: "",
                likes: 0,
              },
            ],
            size: 33,
            benefits: [{ name: "TV", link: "smart_display" }],
          },
        ],
        benefits: [
          {
            name: "Wifi",
            ico: "wifi",
            img: "../assets/Photos/Front Page/main.webp",
            link: "",
            location: "",
            distance: 0,
            info: "",
            atractions: false,
          },
          {
            name: "Grill",
            ico: "restaurant",
            img: "../assets/Photos/Front Page/main.jpg",
            link: "asd sadsa",
            location: "www.google.com",
            distance: 3.5,
            info: "Some info here",
            atractions: false,
          },
          {
            name: "Klimatyzacja",
            ico: "ac_unit",
            img: "../assets/Photos/Front Page/main.jpg",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: false,
          },
          {
            name: "Sauna *",
            ico: "airwave",
            img: "../assets/Photos/IMG_20220103_201223.webp",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: true,
          },
          {
            name: "Parking",
            ico: "local_parking",
            img: "../assets/Photos/Front Page/main.jpg",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: false,
          },
          {
            name: "Basen *",
            ico: "pool",
            img: "../assets/Photos/Front Page/main.jpg",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: false,
          },
          {
            name: "Przechowanie Sprzętu",
            ico: "garage_home",
            img: "../assets/Photos/Front Page/main.jpg",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: false,
          },
          {
            name: "Grill",
            ico: "outdoor_grill",
            img: "../assets/Photos/Front Page/main.jpg",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: false,
          },
          {
            name: "Netflix",
            ico: "smart_display",
            img: "../assets/Photos/Front Page/main.jpg",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: false,
          },
          {
            name: "Express do Kawy",
            ico: "coffee_maker",
            img: "../assets/Photos/Front Page/main.jpg",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: false,
          },
          {
            name: "Sejf",
            ico: "lock",
            img: "../assets/Photos/Front Page/main.jpg",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: false,
          },
        ],
        atractions: [
          {
            name: "Góry",
            ico: "Landscape",
            img: "../assets/Photos/IMG_20210914_105445.webp",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: true,
          },
          {
            name: "Narty",
            ico: "downhill_skiing",
            img: "../assets/Photos/IMG_20220109_103455.webp",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: true,
          },
          {
            name: "Snowboard",
            ico: "snowboarding",
            img: "../assets/Photos/IMG_20220107_155913.webp",
            link: "",
            location: "",
            distance: 3.5,
            info: "",
            atractions: true,
          },
        ],
        // transports:[
        //   {
        //     name: "Train",
        //     ico : "train",
        //     img: "../assets/Photos/Front Page/main.jpg",
        //     link: "",
        //     location: "",
        //     distance: 0,
        //     info: "",
        //     atractions: false,
        //   },
        //   {
        //     name: "Bus",
        //     ico : "commute",
        //     img: "../assets/Photos/Front Page/main.jpg",
        //     link: "",
        //     location: "",
        //     distance: 0,
        //     info: "",
        //     atractions: false,
        //   },
        // ],
        photos: [
          {
            name: "Back Garden",
            src: "../assets/Photos/IMG_20210914_105445.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
          {
            name: "Front Door",
            src: "../assets/Photos/IMG20220621174049.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
          {
            name: "Scenery",
            src: "../assets/Photos/IMG-20220109-WA0011.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
          {
            name: "Front Door",
            src: "../assets/Photos/IMG_20220103_201223.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
          {
            name: "Front Door",
            src: "../assets/Photos/IMG-20201214-WA0008.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
          {
            name: "Scenery",
            src: "../assets/Photos/IMG20220621174547.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
          {
            name: "Front Door",
            src: "../assets/Photos/IMG20220621174804.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
          {
            name: "Scenery",
            src: "../assets/Photos/IMG20220621174012.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
          {
            name: "Scenery",
            src: "../assets/Photos/IMG_4734.webp",
            mini: "",
            loc: "",
            insta: "",
            views: "",
            likes: 0,
          },
        ],
        reviews: [
          {
            name: "Google Places",
            score: 5,
            outOf: 5,
            link: "https://www.google.com/travel/hotels/Sykowno%20Chata/entity/CgoI2fferJ-ekO9HEAE/reviews?q=Sykowno%20Chata&g2lb=2502548%2C2503771%2C2503781%2C2503998%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4515404%2C4597339%2C4649665%2C4703207%2C4718358%2C4722900%2C4723331%2C4741664%2C4743109%2C4754388%2C4757164%2C4758190%2C4758493%2C4762561%2C4779784%2C4781080%2C4786958%2C4787395%2C4790928%2C4794648%2C4797056&hl=en-IE&gl=ie&cs=1&ssta=1&rp=OAJAAEgBwAEC&ictx=1&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABoECgIaACoECgAaAA",
            svg: "../assets/SVG/google.svg",
            reviews: [
              {
                name: "Kamil K.",
                review:
                  "Fantastyczne miejsce. Spędziliśmy tutaj wspaniały weekend który na dłuuuugo zapamiętamy. Warunki na najwyższym poziomie i bardzo sympatyczna i pomocna Pani właścicielka. Polecamy.",
                img: "",
                score: 5,
                outOf: 5,
              },
              {
                name: "Aleksandra M.",
                review:
                  "Bardzo klimatyczne miejsce. W ciszy i spokoju można wypić na tarasie kawę. Bardzo sympatyczni właściciele, chętni do pomocy. Apartamenty zadbane czyste tak jak lubimy. Jeszcze tam wrócimy 😃",
                img: "",
                score: 5,
                outOf: 5,
              },
              {
                name: "Ewelina P.",
                review:
                  "Czyściutko,piękny domek, pyszna kawa z expresu, bardzo miła właścicielka, która dba o gości. Serdecznie polecam, my będziemy przyjeżdżać tylko tutaj.",
                img: "",
                score: 5,
                outOf: 5,
              },
            ],
          },
          {
            name: "Booking.com",
            score: 9.8,
            outOf: 10,
            link: "https://www.booking.com/hotel/pl/sykowno-chata.en-gb.html?aid=304142&label=gen173nr-1DCAEoggI46AdIM1gEaGmIAQGYAQm4ARfIAQzYAQPoAQGIAgGoAgO4AorI5pUGwAIB0gIkZjAzNDYzNjQtNzdmNC00OWM5LTk5ZWEtYTY4NTlkMjNjMmEy2AIE4AIB&sid=f94668a02a4a18b004805a719ee2b3ba&dest_id=2861716;dest_type=hotel;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1656333358;srpvid=8c205896533d0200;type=total;ucfs=1&#tab-reviews",
            svg: "../assets/SVG/booking.svg",
            reviews: [
              {
                name: "Iryna",
                review: "Nadzwyczajny",
                img: "",
                score: 10,
                outOf: 10,
              },
              {
                name: "Cristina",
                review: "Idealne",
                img: "",
                score: 10,
                outOf: 10,
              },
              {
                name: "Sylwia",
                review: "Polecamy każdemu to miejsce",
                img: "",
                score: 10,
                outOf: 10,
              },
            ],
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scope>
  @import "assets/scss/root";
  #app {
    left: 0;
    right: 0;
    width: 100vw;
    margin-top: -1em;
    overflow-x: hidden;
  }
</style>
