import { createApp } from "vue";

// Translations
// import { createI18n } from 'vue-i18n'
// import translations from './data/translations.json';

// const i18n = createI18n({
//     locale: 'en', // set locale
//     fallbackLocale: 'en', // set fallback locale
//     translations,
// })

import App from "./App.vue";
import router from "./router";

const app = createApp(App);

// app.use(i18n)
app.use(router);
app.mount("#app");
