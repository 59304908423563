<template>
  <header>
    <nav id="nav">
      <!-- Logo -->
      <div class="brand-logo">
        <router-link to="/">
          <LogoSVG />
        </router-link>
      </div>
      <!-- Hamburger menu -->
        <div @click="openNavitaion()" :class="[open ? 'toggle-nav inactive' : 'toggle-nav active']">
          <span class="bar top"></span>
          <span class="bar midd"></span>
          <span class="bar bott"></span>
        </div>
      <!-- Links -->
      <div class="nav-links" id="nav-links">
        <ul>
          <li v-for="route in config.routes" :key="route.id">
            <router-link v-if="route.active && route.nav" :to="{name: route.route}">{{route.name}}</router-link>
          </li>
          <li v-if="config.features.reserve.active" class="link-button">
            <button class="button">
              Zarezerwój
            </button>
          </li>
        </ul>
        <div v-if="config.features.darkMode.active" @click="darkMode()" class="darkMode">
            <a href="#">
              dark Mode
            </a>
          </div>
        <!-- Social Media -->
        <div id="nav-social">
          <a v-for="social in contact.socialNetworks" :key="social.id" :href="social.link" target="_blanc">
            <img :src=social.svg :alt=social.name class="imgSVG"/>
          </a>
          <br>
          <a :href="contact.phone[0].link" target="_blanc">
            <img :src=contact.phone[0].svg class="imgSVG" :alt=contact.phone[0].name />
          </a>
          <a :href="contact.email[0].link" target="_blanc">
            <img :src=contact.email[0].svg :alt=contact.email[0].name class="imgSVG" />
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
@import "../assets/scss/variables";
header{
  width: 100vw;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  position: fixed;
  z-index: 100;
}
nav {
  z-index: 99;
  margin: auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  overflow: hidden;
  .nav-links{
    height: 100%;
    ul {
      border-radius: $rad-1;
      display: flex;
      margin: 0;
      padding: 0 1em 0 0;
      li {
        list-style: none;
        a{
          display: block;
          text-decoration: none;
          padding: 1rem;
        }
      }
    }
    .link-button{
      button{
        padding:1em;
        margin: .5em;
      }
    }
    .darkMode{
      display:block;
    }
  }
  .route-active{
    color: $acc-1;
  }
  .toggle-nav{
      position: absolute;
      cursor: pointer;
      top: 1em;
      right: 2em;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: 21px;
      .bar{
        border-radius: 10em;
        height: 4px;
        width: 100%;
        background-color: $txt-1;
        border-radius: 10px;
        transition: $tr-f;
      }
    }
    .inactive{
    transition: $tr-f;
      &:hover  .midd{
        width: 20px;
      }
      &:hover  .bott{
        width: 10px;
      }
    }
  .active{
    transition: $tr-f;
      .midd{
        display: none;
      }
      .top{
        margin-top:8px;
        margin-bottom: -5px;
        transform: rotate(45deg);
      }
      .bott{
        margin-bottom: 9px;
        margin-top: -5px;
        transform: rotate(-45deg);
      }
      &:hover{
        transform: rotate(180deg);
      }

  }
  .svg {
    margin: 0 0 0 1em;
    fill: $txt-1;
    height: 3em;
  }
}
#nav-social{
  text-align: center;
  width: 100vw;
  bottom: 1em;
  a{
    span{
      text-shadow: 1px 1px 25px rgb(59, 59, 59);
      color: $acc-1;
      font-size: 2.5em;
      padding:1em;
    }
    .imgSVG {
      height: 5em;
      padding: 1em;
    }
  }
}
</style>

<script>
import { ref } from "@vue/reactivity";
import LogoSVG from "../components/SVGs/LogoSVG.vue";

export default {
  components: {
    LogoSVG,
  },
  props: {
    contact:{
      type: Array,
      required: true
    },
    config:{
      type: Object,
      required: true
    },
  },
  setup(){
    let open = ref(true);

    function openNavitaion(){
      open.value = !open.value;
      if(!open.value){
       document.getElementById("nav").style.height = "100vh";
      }else{
       document.getElementById("nav").style.height = "3.5em";
      }
    }
    return {open, openNavitaion}
  },
  methodes:{
    show: function darkMode(){
      prompt("Hello");
      return 0;
    }
  }
};
</script>