import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName : "Home"*/ "@/views/HomeScreen.vue"),
  },
  {
    path: "/atrakcje",
    name: "Atractions",
    component: () =>
      import(/* webpackChunkName : "Home"*/ "@/views/AtrakcjePage.vue"),
  },
  {
    path: "/galeria",
    name: "Galery",
    component: () =>
      import(/* webpackChunkName : "Home"*/ "@/views/GaleriaPage.vue"),
  },
  {
    path: "/apartamenty",
    name: "Apartaments",
    component: () =>
      import(/* webpackChunkName : "Home"*/ "@/views/ApartamentyPage.vue"),
  },
  {
    path: "/apartament/:name",
    name: "Apartament",
    component: () =>
      import(/* webpackChunkName : "Home"*/ "@/views/ApartamentPage.vue"),
  },
  {
    path: "/pomoc",
    name: "Help",
    component: () =>
      import(/* webpackChunkName : "Home"*/ "@/views/HelpPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "route-active",
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

export default router;
