<template>
  <footer>
    <logoSVG />
    <div class="contact">
      <!-- Rooms -->
      <div>
        <h4>Oferty</h4>
        <ul v-for="room in rooms" :key="room.id">
          <router-link :to="{ name: 'Apartaments' }">
            {{ room.name }}
          </router-link>
        </ul>
      </div>
      <!--                                                Find Us -->
      <div>
        <h4>Znajdź Nas</h4>
        <div
          v-for="social in defaults.contact.socialNetworks"
          :key="social.id"
          class="findUs"
        >
          <a :href="social.link" target="_self">
            <img :src="social.svg" :alt="social.name" class="socialsvg" />
          </a>
        </div>
      </div>
      <!--                                                    Address -->
      <div>
        <h4>Adres</h4>
        <a
          v-html="replaceWithBr_location()"
          :href="defaults.location.addressMaps"
          class="nav-a"
          target="_blanc"
        >
        </a>
      </div>
      <!--                                                    Contact -->
      <div>
        <h4>Kontakt</h4>
        <a
          v-for="email in defaults.contact.email"
          :key="email.id"
          :href="'mailto:' + email.address"
          target="_blanc"
        >
          <!-- <span>{{ email.name }}</span> - -->
          {{ email.address }} </a
        ><br /><br />
        <a
          v-for="phone in defaults.contact.phone"
          :key="phone.id"
          :href="'tel:' + phone.prefix + phone.number"
          target="_blanc"
        >
          <!-- <span>{{phone.name}}</span> -  -->
          {{ phone.prefix }} {{ phone.number }}
        </a>
      </div>
      <div>
        <!-- Help -->
        <h4 v-if="config.features.help.active">Informacje</h4>
        <router-link v-if="config.features.help.active" :to="{ name: 'Help' }">
          Pomoc
        </router-link>
        <!-- Language Support -->
        <h4 v-if="config.features.languageSupport.active">Języki</h4>
        <div v-if="config.features.languageSupport.active" class="languages">
          <a
            v-for="language in config.features.languageSupport.languages"
            class="langButton"
            :key="language"
          >
            <img :src="language.flag" :alt="language.name" />
            {{ language.name }}
          </a>
        </div>
      </div>
    </div>
    <div id="watermark">
      <p>
        Strona Zbudowana Przez
        <strong>
          <a href="https://www.robertbinkowski.me/" target="_blanc">
            Robert Binkowski
          </a>
        </strong>
      </p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  @import "@/assets/scss/variables";

  footer {
    padding-top: 2em;
    min-height: 10vh;
    color: $fott-txt;
    a {
      color: $fott-txt;
      transition: $tr-s;
      &:hover {
        color: $acc-1;
      }
    }
    .svg {
      height: 6em;
    }
    span {
      color: inherit;
    }
    .socialsvg {
      height: 1em;
    }
    .contact {
      width: 100vw;
      justify-content: center;
      div {
        padding: 1em;
        .socaialMedia {
          display: flex;
        }
        h4 {
          border-bottom: 1px solid $acc-2;
          padding-bottom: 1em;
        }
      }
      .findUs {
        display: inline;
        font-size: 2em;
        padding: 0.3em;
      }
    }
    .languages {
      display: grid;
      grid-gap: 1em;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      .langButton {
      }
    }
    #watermark {
      position: relative;
      margin-top: 7em;
      bottom: 0em;
      padding: 1em 2em 1em 0;
      background-color: #767676;
      color: white;
      a {
        color: white;
        &:hover {
          color: $acc-2;
        }
      }
      text-align: right;
    }
  }
</style>

<script>
  import LogoSVG from "@/components/SVGs/LogoSVG.vue";

  export default {
    name: "app",
    components: {
      LogoSVG,
    },
    props: {
      config: {
        type: Object,
        require: true,
      },
      defaults: {
        type: Object,
        require: true,
      },
      rooms: {
        type: Array,
        require: true,
      },
    },
    methods: {
      replaceWithBr_location() {
        return this.defaults.location.address.replace(/\n/g, "<br />");
      },
    },
  };
</script>
